export const HOSTNAME = {
  LOCALHOST: 'localhost',
  DEMO: 'demo.ott.admon.com.vn',
  TERA: 'tera.ott.admon.com.vn',
  TERA_STAGING: 'tera.ott-staging.admon.com.vn',
  THVLI: 'thvli.ott.admon.com.vn',
  VTCNOW: 'vtcnow.ott.admon.com.vn',
  VTCNOW_DEV: 'vtcnow.ott-dev.admon.com.vn',
  VTCNOW_STAGING: 'vtcnow.ott-staging.admon.com.vn',
  BRT: 'brt.ott.admon.com.vn',
  BRT_STAGING: 'brt.ott-staging.admon.com.vn',
  SDTV: 'sdtv.ott.admon.com.vn',
  TAYNINH: 'tayninh.ott.admon.com.vn',
  TAYNINH_STAGING: 'tayninh.ott-staging.admon.com.vn',
  DEMOA: 'demoa.ott.admon.com.vn',
  DEMOB: 'demob.ott.admon.com.vn'
}

export const DOMAIN_LOGIN_DEMO = {
  MOBIFONE: 'admin.demo.5gplay.vn'
}
